import ScrollMagic from 'scrollmagic';
import {hidePageLoader, showPageLoader} from './components/page-loading.js';
import {initCookiesNotification} from './components/cookies-notification.js';
import { sendDataLayerEvent } from './components/gtm-controller.js';

/**
 * Manages resource loading priority and lazy loading
 */
const ResourceLoader = {
    initYouTubeVideoLazyLoad() {
        const videoIframe = document.querySelector('.benefits__video');
        
        if (!videoIframe) return;

        videoIframe.setAttribute('loading', 'lazy');
    },

    preloadCriticalResources() {
        const lightboxVideo = document.getElementById('lightbox-video-background-video');
        if (lightboxVideo) {
            lightboxVideo.load();
        }
    },

    init() {
        this.initYouTubeVideoLazyLoad();
        this.preloadCriticalResources();
    }
};

function trackFirstVisit() {
    const FIRST_VISIT_KEY = 'isFirstVisit';
    
    const isFirstVisit = localStorage.getItem(FIRST_VISIT_KEY) === null;
    
    if (isFirstVisit) {
        localStorage.setItem(FIRST_VISIT_KEY, 'false');
        
        sendDataLayerEvent('user_first_visit');
    }
}

function setVhVariable() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('load', setVhVariable);
window.addEventListener('resize', setVhVariable);

document.addEventListener('DOMContentLoaded', () => {
    ResourceLoader.init();

    trackFirstVisit();
    initCookiesNotification();

    // Lazy load lightbox viewer
    import('./components/lightbox-viewer.js')
        .then(module => module.initLightboxViewer())
        .catch(error => console.error('Failed to load lightbox viewer:', error));

    // Lazy load quizzes
    import('./components/quiz.js')
        .then(module => module.initQuizzes())
        .catch(error => console.error('Failed to load quizzes:', error));

    // Lazy load modals
    import('./components/modals.js')
        .then(module => module.initModals())
        .catch(error => console.error('Failed to load modals:', error));

    // Lazy load address suggestions
    import('./components/address-suggestions.js')
        .then(module => module.initAddressSuggestionsHandler())
        .catch(error => console.error('Failed to load address suggestions:', error));

    // Lazy load input masks
    import('./components/input-masks.js')
        .then(module => module.initInputMasks())
        .catch(error => console.error('Failed to load input masks:', error));

    // Lazy load sticky button
    import('./components/sticky-button.js')
        .then(module => module.initStickyButton())
        .catch(error => console.error('Failed to load sticky button:', error));

    const benefitsVideoIframe = document.querySelector('.benefits__video');
    if (benefitsVideoIframe) {
        window.addEventListener('message', (event) => {
            if (event.origin.includes('youtube.com')) {
                const data = JSON.parse(event.data);
                if (data.event === 'onStateChange') {
                    if (data.info === 1) {
                        sendDataLayerEvent('benefits_youtube_video_started');
                    }
                }
            }
        }, false);
    }

    const controller = new ScrollMagic.Controller();
    const initFlags = {
        accordions: false,
        imageGalleries: false,
        lightboxPictureDemo: false,
    };

    new ScrollMagic.Scene({
        triggerElement: '.about-lamp',
        triggerHook: 0.8,
    })
        .on('enter', () => {
            if (!initFlags.lightboxPictureDemo) {
                import('./components/lightbox-picture-demo.js')
                    .then(module => {
                        module.initLightboxPictureDemo();
                        initFlags.lightboxPictureDemo = true;
                    })
                    .catch(error => console.error('Failed to load lightbox picture demo:', error));
            }
        })
        .addTo(controller);

    new ScrollMagic.Scene({
        triggerElement: '.benefits',
        triggerHook: 0.8,
    })
        .on('enter', () => {
            if (!initFlags.accordions) {
                import('./components/accordions.js')
                    .then(module => {
                        module.initAccordions();
                        initFlags.accordions = true;
                    })
                    .catch(error => console.error('Failed to load accordions:', error));
            }
        })
        .addTo(controller);

    new ScrollMagic.Scene({
        triggerElement: '.lightbox-demo-picture',
        triggerHook: 0.8,
    })
        .on('enter', () => {
            if (!initFlags.imageGallery) {
                import('./components/image-gallery.js')
                    .then(module => {
                        module.initImageGallery();
                        initFlags.imageGallery = true;
                    })
                    .catch(error => console.error('Failed to load image gallery:', error));
            }
        })
        .addTo(controller);

    const lightboxDemoPictureButton = document.getElementById('lightbox-demo-picture-button');
    if (lightboxDemoPictureButton) {
        lightboxDemoPictureButton.addEventListener('click', () => {
            sendDataLayerEvent('btn_lightbox_img_demo_turn_on');
        });
    }

    document.querySelectorAll('.accordion-item').forEach(accordionItem => {
        accordionItem.addEventListener('click', () => {
            sendDataLayerEvent('btn_accordion_clicked');
        });
    });

    document.querySelectorAll('.btn-cta-create-piclight').forEach(button => {
        button.addEventListener('click', function () {
            const section = button.closest('.benefits') ? 'benefits' : 'details';

            if (section === 'benefits') {
                sendDataLayerEvent('btn_create_piclight_benefits_clicked');
            } else {
                sendDataLayerEvent('btn_create_piclight_details_clicked');
            }

            const mainHeader = document.querySelector('#main-header');
            mainHeader.scrollIntoView({behavior: 'smooth'});
        });
    });

    // CSS Loading Detection
    const stylesheets = document.querySelectorAll('link[rel="stylesheet"]');
    stylesheets.forEach(stylesheet => {
        stylesheet.addEventListener('load', () => {
            hidePageLoader();
        });
    });

    // Fallback timeout
    setTimeout(hidePageLoader, 1000);
});
