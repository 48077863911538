export function initCookiesNotification() {
	const cookiesNotification = document.getElementById('cookies-notification');
	const acceptCookiesBtn = document.getElementById('btn-accept-cookies');

	const isCookiesAccepted = localStorage.getItem('isCookiesAccepted');

	if (!isCookiesAccepted) {
		cookiesNotification.classList.remove('hidden');
	} else {
		cookiesNotification.classList.add('hidden');
	}

	acceptCookiesBtn.addEventListener('click', () => {
		cookiesNotification.classList.add('hidden');
		localStorage.setItem('isCookiesAccepted', 'true');
	});
}
