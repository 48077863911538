export function showPageLoader() {
	const loader = document.getElementById('page-loading');
	if (loader) {
		loader.classList.remove('hidden');
	}
}

export function hidePageLoader() {
	const loader = document.getElementById('page-loading');
	if (loader) {
		loader.classList.add('hidden');
	}
}
