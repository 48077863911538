const DataLayerController = (() => {
    const dataLayerFiredEvents = new Set();

    /**
     * Logs event to backend database
     * @param {string} eventName - Name of the event to log
     * @returns {Promise<void>} Promise resolving when event is logged
     */
    async function logEventToDatabase(eventName) {
        try {
            const utmParams = extractUTMParameters();
            
            const response = await fetch('/api/log-event', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventName,
                    timestamp: new Date().toISOString(),
                    userAgent: navigator.userAgent,
                    url: window.location.href,
                    ...utmParams
                })
            });

            if (!response.ok) {
                console.warn(`Failed to log event ${eventName} to database`);
            }
        } catch (error) {
            console.error('Error logging event to database:', error);
        }
    }

    /**
     * Extracts UTM parameters from the current URL
     * @returns {Object} UTM parameters
     */
    function extractUTMParameters() {
        const urlParams = new URLSearchParams(window.location.search);
        return {
            utmSource: urlParams.get('utm_source') || null,
            utmMedium: urlParams.get('utm_medium') || null,
            utmCampaign: urlParams.get('utm_campaign') || null,
            utmTerm: urlParams.get('utm_term') || null,
            utmContent: urlParams.get('utm_content') || null
        };
    }

    /**
     * Adds an event to the fired events set
     * @param {string} event - The event to add
     */
    function addEventToFiredEvents(event) {
        dataLayerFiredEvents.add(event);
    }

    /**
     * Checks if an event has already been fired
     * @param {string} event - The event to check
     * @returns {boolean} Whether the event has been fired
     */
    function hasEventFired(event) {
        return dataLayerFiredEvents.has(event);
    }

    /**
     * Sends an event to the dataLayer if it hasn't been fired before
     * @param {string} sendEvent - The event to send
     */
    function sendDataLayerEvent(sendEvent) {
        if (typeof window === 'undefined' || !window.dataLayer) {
            console.warn('DataLayer not available');
            return;
        }

        if (hasEventFired(sendEvent)) {
            console.log(`Event ${sendEvent} already fired`);
            return;
        }

        window.dataLayer.push({
            event: sendEvent
        });

        logEventToDatabase(sendEvent);

        addEventToFiredEvents(sendEvent);
    }

    /**
     * Resets the fired events (for testing)
     */
    function resetFiredEvents() {
        dataLayerFiredEvents.clear();
    }

    /**
     * Get current fired events (for debugging)
     * @returns {Set} Set of fired events
     */
    function getFiredEvents() {
        return new Set(dataLayerFiredEvents);
    }

    return {
        sendDataLayerEvent,
        hasEventFired,
        resetFiredEvents,
        getFiredEvents,
        logEventToDatabase,
        extractUTMParameters
    };
})();

export default DataLayerController;

export const {
    sendDataLayerEvent,
    hasEventFired,
    resetFiredEvents,
    getFiredEvents,
    logEventToDatabase,
    extractUTMParameters
} = DataLayerController;